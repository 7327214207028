import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const redirectionView = () =>
    import('@/components/redirection/redirection.view')
const mainTemplateView = () =>
    import('@/components/mainTemplate/main.view')
const mainContentView = () =>
    import('@/components/mainTemplate/mainContent')
const sesionesLiveView = () =>
    import('@/components/sesiones-live/sesionesLive.view')
const sesionLiveView = () =>
    import('@/components/sesiones-live/sesionLive.view')
const administrarView = () =>
    import('@/components/administrar/administrar.view')
const adminSesionView = () =>
    import('@/components/administrar/adminSesion.view')
const consejosView = () =>
    import('@/components/consejos/consejos.view')
const consejoView = () =>
    import('@/components/consejos/consejo.view')
const usuariosView = () =>
    import('@/components/usuarios/usuarios.view')
const dependenciasView = () =>
    import('@/components/dependencias/dependencias.view')
const constanciasView = () =>
    import('@/components/consejos/constancias/constancias.view')
const constanciasMain = () => import('@/components/consejos/constanciasMain')

export default new Router({
    mode: 'history', // https://router.vuejs.org/api/#mode
    linkActiveClass: 'open active',
    scrollBehavior: () => ({ y: 0 }),
    routes: [
        { path: '/redirect/:token', name: 'Redirect', component: redirectionView },
        {
            path: '/', redirect: '/main/sesiones', component: mainTemplateView,
            children: [
                {
                    path: 'main',
                    name: 'mainContent',
                    redirect: '/main/sesiones',
                    component: mainContentView,
                    children: [
                        {
                            path: 'sesiones',
                            name: 'sesiones',
                            component: sesionesLiveView
                        },
                        {
                            path: 'admin',
                            name: 'administrar',
                            component: administrarView
                        },
                        {
                            path: 'consejos',
                            name: 'consejos',
                            component: consejosView
                        },
                        {
                            path: 'usuarios',
                            name: 'usuarios',
                            component: usuariosView
                        },
                        {
                            path: 'dependencias',
                            name: 'dependencias',
                            component: dependenciasView
                        },
                        {
                            path: 'constancias',
                            name: 'constancias',
                            component: constanciasMain
                        },

                    ]
                },
                {
                    path: 'sesion/:idSesion',
                    name: 'sesionLive',
                    component: sesionLiveView
                },
                {
                    path: 'admin/sesion/:idSesion',
                    name: 'administrarSesion',
                    component: adminSesionView
                },
                {
                    path: 'consejo/:idConsejo',
                    name: 'consejo',
                    component: consejoView,
                   
                },
                // {
                //     path:'enviarCorreos', name: 'Correo', component:Correos
                // }
                
            ]
        },

        {   
            path:'/cgu-constancias/:idHash', name: 'Constancias', component:constanciasView
        },

        {
            //Cualquier ruta que no exista, se redireccionará al main
            path: '*',
            redirect: '/main/sesiones',
            // name: 'Not found',
            // component: Page404
        }
    ]
})