import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router/router'
import { store } from './store/store'
import Notifications from 'vue-notification'
import Notificaciones from './plugins/notificaciones'
import ValidateResponse from './plugins/validateResponse'
import Vue2Editor from "vue2-editor";
import VModal from 'vue-js-modal';
import io from 'socket.io-client'

//local
// const socket = io('http://localhost:3031',
//prod
const socket = io('https://sesiones-hcgu.udg.mx',
//test
// const socket = io('http://148.202.143.90:3031',
    {
        path: '/socket.io',
        transports:['websocket']
    })

Vue.prototype.$socket = socket

Vue.use(Vue2Editor);
Vue.config.productionTip = false

Vue.use(Notifications)
Vue.use(Notificaciones)
Vue.use(ValidateResponse, store)

Vue.use(VModal, { componentName: 'modal' });

import VuePdfApp from "vue-pdf-app";
// import this to use default icons for buttons
import "vue-pdf-app/dist/icons/main.css";

new Vue({
    vuetify,
    router,
    store,
    VuePdfApp,
    render: h => h(App)
}).$mount('#app')

//TODO:Configuración que se intentó para conectar los sockets
// withCredentials:true,
    // extraHeaders:{
    //     'Access-Control-Allow-Origin':'*'
    // },
    // cors: {
    //     // origins: '*:*',
    //     // origin: 'http://127.0.0.1:8083',
    //     origin: 'http://148.202.143.90:8083',
    //     // //origin:'http://sesiones-hcgu.udg.mx
    //     methods: ['GET', 'POST'],
    //     // extraHeaders: {'cabeceras':'Access-Control-Allow-Origin'}
    // },